import React from "react";
import Header from "./components/header";
import Content from "./components/content";
import Footer from "./components/footer";

import "./assets/css/bootstrap-icons.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/fonts.css";
import "./assets/css/main.css";

function App() {
  return (
    <>
      <Header />
      <div className="content">
        <Content />
      </div>
      <Footer />
    </>
  );
}

export default App;
