export default function Footer() {
  return (
    <footer className="footer mt-auto py-3 bg-light fixed-bottom">
      <div className="container">
        <div className="row">
          <div className="col-4 d-flex">
            <span className="logo align-self-end">Cyber Żyrardów</span>
          </div>
          <div className="col-4 d-flex justify-content-center">
            <a
              className="infomail align-self-end"
              href="mailto:info@cyberzyrardow.pl"
            >
              info@CyberŻyrardów.pl
            </a>
          </div>
          <div className="col-4 d-flex"></div>
        </div>
      </div>
    </footer>
  );
}
